var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("PDF Rapportages")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeOrganization,
                    callback: function($$v) {
                      _vm.activeOrganization = $$v
                    },
                    expression: "activeOrganization"
                  }
                },
                [
                  _vm._l(_vm.organizations, function(organization, index) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: "planning-tab-" + index,
                          staticClass: "tab__filled",
                          class: {
                            active: _vm.activeOrganization === organization.id
                          },
                          attrs: {
                            href: "#" + organization.id,
                            disabled: _vm.isLoading
                          }
                        },
                        [_vm._v(" " + _vm._s(organization.name) + " ")]
                      )
                    ]
                  }),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeOrganization,
                        callback: function($$v) {
                          _vm.activeOrganization = $$v
                        },
                        expression: "activeOrganization"
                      }
                    },
                    [
                      _vm._l(_vm.organizations, function(organization) {
                        return [
                          _c(
                            "v-tab-item",
                            {
                              key: "planning-tab-content-" + organization.id,
                              attrs: {
                                lazy: true,
                                transition: false,
                                "reverse-transition": false,
                                value: organization.id
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "elementPanel" },
                                [
                                  _vm.$store.state.isServiceOrganization
                                    ? _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                "text-right": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "MiButton",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    small: "true",
                                                    icon: "picture_as_pdf"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.creatingPeriodePdf(
                                                        organization
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Rapportage genereren "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("DataTable", {
                                            ref: "managementReportDatatable",
                                            refInFor: true,
                                            attrs: {
                                              options: _vm.tableOptions,
                                              visibility: _vm.visibility
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isCreatingPeriodePdf
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: true, "max-width": "600" },
              model: {
                value: _vm.isCreatingPeriodePdf,
                callback: function($$v) {
                  _vm.isCreatingPeriodePdf = $$v
                },
                expression: "isCreatingPeriodePdf"
              }
            },
            [
              _c(
                "DefaultDialog",
                { on: { close: _vm.closePeriodePdfDialog } },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v(
                      _vm._s(_vm.selectedOrganization.name) +
                        ": PDF Rapportage aanmaken"
                    )
                  ]),
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.isLoading
                                ? _c("LoaderCard", {
                                    attrs: {
                                      flat: "",
                                      type: "spinner--center",
                                      minHeight: "250px"
                                    }
                                  })
                                : _vm._e(),
                              !_vm.isLoading
                                ? [
                                    _c("PeriodFilter", {
                                      on: { change: _vm.applyDates }
                                    })
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      !_vm.isLoading
                        ? _c(
                            "v-layout",
                            {
                              attrs: { "justify-end": "", "align-center": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "fadedText link mr-3",
                                        on: { click: _vm.closePeriodePdfDialog }
                                      },
                                      [_vm._v("Annuleren")]
                                    ),
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.isDisabled,
                                          small: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.createManagementReport(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Aanmaken ")]
                                    )
                                  ]
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }