import { Options } from '@/components/mi-dialog/MiDialog';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import DataTable, { TableOptions, TableVisibility } from '@/components/data-table/DataTable';
import { formatDate } from '@/support/String';
import { ManagementReport } from '@/models/ManagementReport';
import { Organization } from '@/models/Organization';
import PeriodFilter from '@/components/filters/period-filter/PeriodFilter.vue';

@Component<PdfRapportages>({
  components: {
    PeriodFilter,
  },
})
export default class PdfRapportages extends Vue {
  public $pageTitle = 'PDF Rapportages';

  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
  */
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */
    protected isLoading = true;

    protected period: Period = {
      start: '',
      end: '',
    }

    // Organizations
    protected organizations: Organization[] | null = null;

    protected selectedOrganization: Organization | null = null;

    protected activeOrganization = '';

    protected selectedManagementReport: ManagementReport | null = null;

    protected visibility: TableVisibility = {
      checkboxes: false,
      total: true,
      title: false,
    }
    // #endregion

    // #region Lifecycle Hooks / Init
    /**
   * ie.
   * protected mounted(): void {
   *  console.log('MyComponent has Mounted');
   * }
  */
    public mounted(): void {
      this.initialize();
    }

    protected async initialize(): Promise<void> {
      this.emitBreadcrumb();

      this.isLoading = true;

      if (this.$store.state.isServiceOrganization) {
        await this.getOrganizations();
      } else {
        this.activeOrganization = this.$store.state.Auth.organization.id;
        this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
      }

      this.isLoading = false;
    }

    protected emitBreadcrumb(): void {
      this.$root.$emit('breadcrumbUpdated',
        {
          crumb: [
            { name: 'Rapportage / PDF Rapportages' },
          ],
        });
    }
    // #endregion

    // #region Class methods
    /**
   * ie.
   * protected toggleLoading(): void {
   *  this.isLoading = ! this.isLoading;
   * }
  */
    protected creatingPeriodePdf(organization: Organization): void {
      this.selectedOrganization = organization;
    }

    protected closePeriodePdfDialog(): void {
      this.selectedOrganization = null;
    }

    protected applyDates(from: string, to: string): void {
      this.period.start = from;
      this.period.end = to;
    }

    protected refreshDataTable() {
      document.dispatchEvent(
        new CustomEvent('datatable:hook', {
          detail: (dataTable: DataTable) => {
            dataTable.refresh();
          },
        }),
      );
    }
    // #endregion

    // #region Async methods
    /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
  */
    protected async getOrganizations(): Promise<void> {
      await new Organization()
        .getAllExperts()
        .then((organizations: Organization[]) => {
          this.organizations = organizations;

          if (this.organizations.length && ! this.activeOrganization) {
            this.activeOrganization = this.organizations[0].id || '';
          }
        })
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });
    }

    protected async createManagementReport(): Promise<void> {
      if (! this.selectedOrganization || ! this.selectedOrganization.id || this.isDisabled) {
        return;
      }

      this.isLoading = true;

      const managementReport = await new ManagementReport()
        .dmz(this.selectedOrganization.id)
        .create({
          period_start: this.period.start,
          period_end: this.period.end,
        })
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });

      this.isLoading = false;

      if (managementReport) {
        this.$router.push(`/rapportage/pdf-rapportages/${managementReport.id}?organization=${this.selectedOrganization.id}`);
      }
    }
    // #endregion

    // #region Getters & Setters
    /**
   * ie.
   * protected get companyName(): string {
   *  return this?.company.name || 'N/A';
   * }
  */

    protected get isDisabled(): boolean {
      return ! this.period.start || ! this.period.end;
    }

    protected get isCreatingPeriodePdf(): boolean {
      return !! this.selectedOrganization;
    }

    protected get tableOptions(): TableOptions {
      return {
        model: new ManagementReport().dmz(this.activeOrganization),
        name: {
          singular: 'Standaard tekst',
          plural: 'Standaard teksten',
        },
        headers: [
          {
            text: 'Naam',
            value: 'name',
            width: '30%',
          },
          {
            text: 'Periode vanaf',
            value: 'period_start',
            transform: (period_start: string) => (period_start ? formatDate(period_start) : '-'),
          },
          {
            text: 'Periode tot',
            value: 'period_end',
            transform: (period_end: string) => (period_end ? formatDate(period_end) : '-'),
          },
          {
            text: 'Status',
            value: 'status',
            transform: (status: string, managementReport: ManagementReport) => managementReport.statusPill,
          },
        ],
        actions: [
          {
            type: 'view',
            label: 'view',
            icon: 'remove_red_eye',
            action: (dataTable: DataTable, managementReport: ManagementReport) => {
              if (managementReport.id) {
                this.$router.push(`/rapportage/pdf-rapportages/${managementReport.id}?organization=${this.activeOrganization}`);
              }
            },
            tooltip: 'Bekijk PDF rapportage',
          },
          {
            type: 'delete',
            label: 'delete',
            icon: 'delete',
            visible: (dataTable: DataTable, managementReport: ManagementReport) => ! managementReport.isFinal,
            action: (dataTable: DataTable, managementReport: ManagementReport) => {
              this.selectedManagementReport = managementReport;
              this.$store.dispatch('openDialog', this.dialogOptionsDelete);
            },
            tooltip: 'PDF rapportage verwijderen',
          },
        ],
      };
    }

    protected get dialogOptionsDelete(): Options {
      return {
        title: this.$t('dialogOptions.confirmation').toString(),
        text: 'Weet je zeker dat je deze rapportage wilt verwijderen?',
        type: 'warning',
        buttons: {
          confirm: {
            color: 'warning',
            text: 'Ja, verwijderen',
            action: async () => {
              await this.selectedManagementReport?.dmz(this.activeOrganization).delete();
              this.refreshDataTable();
            },
          },
          cancel: {
            text: this.$t('dialogOptions.button.cancel').toString(),
            color: 'text-light',
            action: () => {
              this.selectedManagementReport = null;
            },
          },
        },
      };
    }
    // #endregion

    // #region @Watchers
    /**
   * ie.
   * @Watch('company')
   * protected companyChanged() {
   *  console.log('Company Changed') ;
   * }
  */
    @Watch('$route', { deep: true })
    public routeChanged(to: any, from: any) {
      this.emitBreadcrumb();
    }
  // #endregion
}

interface Period {
  start: string;
  end: string;
}
